.App {
  display: grid;
}

body{
  background: rgb(29, 21, 3);
}

*{
  padding: 0;
  margin: 0;
}

#Maintenance{
  display: grid;
  gap: 10px;
  text-align: center;
  color: #e7b568;
  justify-items: center;
  margin: 200px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}